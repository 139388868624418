"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WsClient = void 0;
var WsClient = /** @class */ (function () {
    function WsClient() {
        this.FUltimoEnvio = new Date();
        this._FGrupo = "";
        this._FSubGrupo = "";
        this.FServidorPrincipalEndereco = "ws.grv.net.br";
        this.FServidorPrincipalPorta = 20021;
        this.FServidorAlternativo1Endereco = null;
        this.FServidorAlternativo1Porta = null;
        this.FServidorAlternativo2Endereco = null;
        this.FServidorAlternativo2Porta = null;
        this.FTentativasReconexaoAntesTrocarServidor = 2;
        this.TentativasReconexao = 0;
        this.aorecebermensagem = null;
        this._ServerId = '';
        this._companyAcessKey = '';
        this._companyID = '';
        this._DtUltimoEnvioMensagem = new Date();
        this.message = "";
        this._HostPersonalizado = "";
        this._FInicioClient = new Date();
        this._FLastPing = new Date();
        this._FLastPong = new Date();
        this._FAutoReconnect = true;
        this._PortaPersonalizada = 20020;
        this._FIDName = "";
        this.mensagem = "";
        this.Acao = 0;
        this.tag = 0;
        this.FIniciouConexao = false;
        this.connected = false;
        this.usuarios = [];
        this.getsql = [];
        this.logsEvents = [];
        this._PortaPersonalizada = 20020;
        this.isLiveInfo = { empresa: null };
        this.InfoAdicional = {};
        this._Versao = '1.0.0.0';
    }
    WsClient.prototype.onislivemessage = function (content) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    WsClient.prototype.connect = function (pIndexServidor) {
        return __awaiter(this, void 0, void 0, function () {
            var delay;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._FIndexServidorHost = pIndexServidor;
                        switch (pIndexServidor) {
                            case 0:
                                this.FServidorPrincipalEndereco = "wss://ws.grv.net.br";
                                this.FServidorPrincipalPorta = 20021;
                                break;
                            case 1:
                                this.FServidorPrincipalEndereco = "wss://ws1.grv.net.br";
                                this.FServidorPrincipalPorta = 20021;
                                break;
                            case 2:
                                this.FServidorPrincipalEndereco = "wss://ws2.grv.net.br";
                                this.FServidorPrincipalPorta = 20021;
                                break;
                            case 3:
                                this.FServidorPrincipalEndereco = this.FServidorPrincipalEndereco;
                                this.FServidorPrincipalPorta = this.FServidorPrincipalPorta;
                                break;
                            default:
                                this.FServidorPrincipalEndereco = "wss://ws.grv.net.br";
                                this.FServidorPrincipalPorta = 20021;
                        }
                        if (this._FIDName == "" || this._FIDName == undefined) {
                            throw new Error("ID Name obrigatorio");
                        }
                        if (this._FGrupo == "" || this._FGrupo == undefined) {
                            throw new Error("Grupo obrigatorio");
                        }
                        if (this._FSubGrupo == "" || this._FSubGrupo == undefined) {
                            throw new Error("Sub Grup obrigatorio");
                        }
                        this.logsEvents.push("connecting... ".concat(this.FServidorPrincipalEndereco, ":").concat(this.FServidorPrincipalPorta, " - ").concat(new Date(), " \n"));
                        this._Sockets = new WebSocket("".concat(this.FServidorPrincipalEndereco, ":").concat(this.FServidorPrincipalPorta, "/sgc/auth/url/wmMensageriaGRV/f47ac10b-58cc-4372-a567-0e02b2c3d479"));
                        this._Sockets.onopen = function (event) {
                            _this.setConnect(event);
                        };
                        this._Sockets.onmessage = function (message) {
                            _this.setMessage(message);
                        };
                        this._Sockets.onclose = function () {
                            _this.setDisconnect();
                        };
                        this._Sockets.onerror = function (erro) {
                            _this.logsEvents.push("erro : ".concat(erro, "  || ").concat(new Date(), " \n"));
                        };
                        delay = function (ms) {
                            return new Promise(function (resolve) { return setTimeout(resolve, ms); });
                        };
                        return [4 /*yield*/, delay(500)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WsClient.prototype.desconnectClient = function (pNomeDestino) {
        return __awaiter(this, void 0, void 0, function () {
            var JSONSendMessage, delay;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        JSONSendMessage = {
                            NomeDestino: pNomeDestino,
                            NomeOrigem: this._FIDName,
                            Tipo: btoa("MSGSERVER"),
                            Acao: btoa("DISCONNECT")
                        };
                        this._Sockets.send("SM|".concat(JSON.stringify(JSONSendMessage)));
                        delay = function (ms) {
                            return new Promise(function (resolve) { return setTimeout(resolve, ms); });
                        };
                        return [4 /*yield*/, delay(500)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WsClient.prototype.PingMessage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var JSONSend, delay;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        JSONSend = {
                            Versao: 1,
                            NomeDestino: this._FIDName,
                            NomeOrigem: this._FIDName,
                            Mensagem: btoa("ping")
                        };
                        this._FLastPing = new Date();
                        this._Sockets.send("SM|".concat(JSON.stringify(JSONSend)));
                        delay = function (ms) {
                            return new Promise(function (resolve) { return setTimeout(resolve, ms); });
                        };
                        // Aguardar por cinco segundos antes de enviar a mensagem
                        return [4 /*yield*/, delay(700)];
                    case 1:
                        // Aguardar por cinco segundos antes de enviar a mensagem
                        _a.sent();
                        return [2 /*return*/, "Pong ".concat(this._FLastPong.toISOString())];
                }
            });
        });
    };
    WsClient.prototype.setDisconnect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var delay;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.logsEvents.push("disconnect ".concat(new Date(), " \n"));
                        console.log("disconnect");
                        this.connected = false;
                        clearInterval(this._OnPing);
                        delay = function (ms) {
                            return new Promise(function (resolve) { return setTimeout(resolve, ms); });
                        };
                        return [4 /*yield*/, delay(500)];
                    case 1:
                        _a.sent();
                        if (this._FAutoReconnect) {
                            switch (this.TentativasReconexao) {
                                case 0:
                                    if ((this.FServidorAlternativo1Endereco != null) && (this.FServidorAlternativo1Porta != null)) {
                                    }
                                    break;
                                case 1:
                                    if ((this.FServidorAlternativo2Endereco != null) && (this.FServidorAlternativo2Porta != null)) {
                                    }
                                    break;
                                case 3:
                                    // principal
                                    break;
                                default:
                                // principal
                            }
                            this.connect(this._FIndexServidorHost);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    WsClient.prototype.setConnect = function (event) {
        this.logsEvents.push("connect ".concat(new Date(), " \n"));
        this.logsEvents.push("connect in ".concat(this.FServidorPrincipalEndereco, ":").concat(this.FServidorPrincipalPorta, " - ").concat(new Date(), " \n"));
        this.connected = true;
        this._FAutoReconnect = true;
        this.login();
        this.loginConnectIot();
    };
    WsClient.prototype.loginConnectIot = function () {
        var login = {
            company_id: this._companyID,
            company_access_key: this._companyAcessKey
        };
        var JsonMessage = {
            NomeOrigem: this.IDName,
            NomeDestino: this._ServerId,
            Tipo: "login",
            Acao: "logar",
            Mensagem: JSON.stringify(login)
        };
        this.SendMessage(JsonMessage);
    };
    WsClient.prototype.Base64toJson = function (pJson) {
        try {
            if (Object.hasOwn(pJson, "Tipo")) {
                if (pJson.Tipo != '') {
                    pJson.Tipo = atob(pJson.Tipo);
                }
            }
            if (Object.hasOwn(pJson, "Acao")) {
                if (pJson.Acao != '') {
                    pJson.Acao = atob(pJson.Acao);
                }
            }
            if (Object.hasOwn(pJson, "Parametro1")) {
                if (pJson.Parametro1 != '') {
                    pJson.Parametro1 = atob(pJson.Parametro1);
                }
            }
            if (Object.hasOwn(pJson, "Parametro2")) {
                if (pJson.Parametro2 != '') {
                    pJson.Parametro2 = atob(pJson.Parametro2);
                }
            }
            if (Object.hasOwn(pJson, "Parametro3")) {
                if (pJson.Parametro3 != '') {
                    pJson.Parametro3 = atob(pJson.Parametro3);
                }
            }
            if (Object.hasOwn(pJson, "Parametro4")) {
                if (pJson.Parametro4 != '') {
                    pJson.Parametro4 = atob(pJson.Parametro4);
                }
            }
            if (Object.hasOwn(pJson, "Parametro5")) {
                if (pJson.Parametro5 != '') {
                    pJson.Parametro5 = atob(pJson.Parametro5);
                }
            }
            if (Object.hasOwn(pJson, "Mensagem")) {
                if (pJson.Mensagem != '') {
                    pJson.Mensagem = atob(pJson.Mensagem);
                }
            }
            return pJson;
        }
        catch (error) {
            console.error(error);
        }
    };
    WsClient.prototype.setMessage = function (pMessage) {
        return __awaiter(this, void 0, void 0, function () {
            var JsonMessage, JsonSendPong, msgapp_islive, JsonAPP, chave, delay, JsonSendMessage, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!JSON.stringify(pMessage.data).includes("SM|")) return [3 /*break*/, 8];
                        JsonMessage = JSON.parse(pMessage.data.substring(3));
                        JsonMessage = this.Base64toJson(JsonMessage);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!(Object.hasOwn(JsonMessage, "Mensagem") &&
                            !Object.hasOwn(JsonMessage, "Tipo"))) return [3 /*break*/, 2];
                        if (JsonMessage.Mensagem == "ping") {
                            JsonSendPong = {
                                NomeDestino: JsonMessage.NomeOrigem,
                                NomeOrigem: JsonMessage.NomeOrigem,
                                Mensagem: btoa("pong"),
                                Parametro1: "ClientWeb",
                                Parametro2: new Date().toISOString()
                            };
                            this._Sockets.send("SM|".concat(JSON.stringify(JsonSendPong)));
                        }
                        if (JsonMessage.Mensagem == "pong") {
                            this._FLastPong = new Date();
                        }
                        return [3 /*break*/, 6];
                    case 2:
                        if (!!Object.hasOwn(JsonMessage, "Mensagem")) return [3 /*break*/, 5];
                        if (!(JsonMessage.Tipo == "islive" &&
                            JsonMessage.Acao == "get")) return [3 /*break*/, 4];
                        msgapp_islive = this.onislivemessage("islive");
                        JsonAPP = {
                            origin: window.origin,
                            StartApp: this._FInicioClient.toISOString(),
                            ExeVersion: this._Versao
                        };
                        for (chave in this.InfoAdicional) {
                            if (Object.hasOwnProperty.call(this.InfoAdicional, chave)) {
                                JsonAPP[chave] = this.InfoAdicional[chave];
                            }
                        }
                        delay = function (ms) {
                            return new Promise(function (resolve) { return setTimeout(resolve, ms); });
                        };
                        return [4 /*yield*/, delay(100)];
                    case 3:
                        _a.sent();
                        JsonSendMessage = {
                            Versao: "1",
                            NomeOrigem: this._FIDName,
                            NomeDestino: JsonMessage.NomeOrigem,
                            Tipo: btoa("islive"),
                            Acao: btoa(".result"),
                            Parametro1: btoa("False"),
                            Parametro2: btoa(JSON.stringify(JsonAPP)),
                            Mensagem: btoa(JSON.stringify(msgapp_islive))
                        };
                        this._Sockets.send("SM|".concat(JSON.stringify(JsonSendMessage)));
                        _a.label = 4;
                    case 4:
                        if (JsonMessage.Tipo == "MSGSERVER" &&
                            JsonMessage.Acao == "DISCONNECT") {
                            this._FAutoReconnect = false;
                            this._Sockets.close();
                        }
                        if (JsonMessage.Tipo == "islive" &&
                            JsonMessage.Acao == ".result") {
                            this.mensagem = JSON.parse(JsonMessage.Parametro2);
                            this.isLiveInfo = JSON.parse(JsonMessage.Parametro2);
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        if (JsonMessage.Tipo == "CPSWEB.SQL" &&
                            JsonMessage.Acao == "SQL.RESULT") {
                            this.getsql = JSON.parse(JsonMessage.Mensagem);
                        }
                        if (JsonMessage.Tipo == "GETUSER" &&
                            JsonMessage.Acao == "RESULT") {
                            this.usuarios = JSON.parse(JsonMessage.Mensagem);
                        }
                        if (JsonMessage.Tipo == "tipo" &&
                            JsonMessage.Acao == "ação") {
                            this.mensagem = JsonMessage.Mensagem;
                        }
                        _a.label = 6;
                    case 6:
                        if (this.aorecebermensagem) {
                            this.aorecebermensagem(JsonMessage);
                        }
                        return [3 /*break*/, 8];
                    case 7:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(WsClient.prototype, "setMensagem", {
        set: function (pmessage) {
            this.mensagem = pmessage;
        },
        enumerable: false,
        configurable: true
    });
    WsClient.prototype.login = function () {
        var JsonLogin = {
            NomeOrigem: this._FIDName,
            Tipo: btoa("LOGIN"),
            Acao: btoa("LOGAR"),
            Parametro1: btoa(this._FGrupo),
            Parametro2: btoa(this._FSubGrupo)
        };
        this._Sockets.send(JSON.stringify(JsonLogin));
    };
    WsClient.prototype.desconnect = function () {
        this._Sockets.close();
    };
    WsClient.prototype.GETSQL = function (pNomeClient, pSQL) {
        return __awaiter(this, void 0, void 0, function () {
            var JsonParams4, SendJson;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        JsonParams4 = [
                            {
                                headers: [
                                    {
                                        "SQL": pSQL,
                                        "Params": "",
                                        "text-plain": "true"
                                    }
                                ]
                            }
                        ];
                        SendJson = {
                            Versao: 1,
                            NomeOrigem: this._FIDName,
                            NomeDestino: pNomeClient,
                            Tipo: btoa("CPSWEB.SQL"),
                            Acao: btoa("SQL.GET"),
                            Parametro4: btoa(JSON.stringify(JsonParams4))
                        };
                        return [4 /*yield*/, this._Sockets.send("SM|" + JSON.stringify(SendJson))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WsClient.prototype.isLive = function (pNomeClient) {
        return __awaiter(this, void 0, void 0, function () {
            var SendJson, delay, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLiveInfo = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        SendJson = {
                            Versao: 1,
                            NomeOrigem: this._FIDName,
                            NomeDestino: pNomeClient,
                            Tipo: btoa("islive"),
                            Acao: btoa("get")
                        };
                        return [4 /*yield*/, this._Sockets.send("SM|" + JSON.stringify(SendJson))];
                    case 2:
                        _a.sent();
                        delay = function (ms) {
                            return new Promise(function (resolve) { return setTimeout(resolve, ms); });
                        };
                        return [4 /*yield*/, delay(700)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, this.isLiveInfo];
                    case 4:
                        error_2 = _a.sent();
                        console.error(error_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    WsClient.prototype.GetUsersServer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var JsonMessage, delay;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        JsonMessage = {
                            Versao: 1,
                            Parametro1: this._FGrupo,
                            Parametro2: this._FSubGrupo
                        };
                        return [4 /*yield*/, this._Sockets.send("GETUSER|" + btoa(JSON.stringify(JsonMessage)))];
                    case 1:
                        _a.sent();
                        delay = function (ms) {
                            return new Promise(function (resolve) { return setTimeout(resolve, ms); });
                        };
                        // Aguardar por cinco segundos antes de enviar a mensagem
                        return [4 /*yield*/, delay(500)];
                    case 2:
                        // Aguardar por cinco segundos antes de enviar a mensagem
                        _a.sent();
                        return [2 /*return*/, this.usuarios];
                }
            });
        });
    };
    WsClient.prototype.SendMessage = function (pClient) {
        return __awaiter(this, void 0, void 0, function () {
            var delay;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pClient.Tipo = btoa(pClient.Tipo);
                        pClient.Acao = btoa(pClient.Acao);
                        pClient.Mensagem = btoa(pClient.Mensagem);
                        pClient.Parametro1 = btoa(pClient.Parametro1);
                        pClient.Parametro2 = btoa(pClient.Parametro2);
                        pClient.Parametro3 = btoa(pClient.Parametro3);
                        pClient.Parametro4 = btoa(pClient.Parametro4);
                        pClient.Parametro5 = btoa(pClient.Parametro5);
                        pClient.NomeOrigem = this._FIDName;
                        if (pClient.NomeDestino == null || pClient.NomeDestino == undefined) {
                            throw new Error("'Informe o nome do destino");
                        }
                        if (!(Math.abs(this._DtUltimoEnvioMensagem.getTime() - new Date().getTime()) <= 10)) return [3 /*break*/, 2];
                        delay = function (ms) {
                            return new Promise(function (resolve) { return setTimeout(resolve, ms); });
                        };
                        return [4 /*yield*/, delay(10)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (this._Sockets.readyState == 1) {
                            this._Sockets.send("SM|" + JSON.stringify(pClient));
                            // console.log(`SM|${JSON.stringify(pClient)}`)
                            this._DtUltimoEnvioMensagem = new Date();
                        }
                        if (this._Sockets.readyState == 0) {
                            console.log('conectando com ws...');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(WsClient.prototype, "IDName", {
        get: function () {
            return this._FIDName;
        },
        set: function (pIDName) {
            this._FIDName = pIDName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "Grupo", {
        set: function (pGrupo) {
            this._FGrupo = pGrupo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "SubGrupo", {
        set: function (pSubGrupo) {
            this._FSubGrupo = pSubGrupo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "HostPersonalizado", {
        set: function (pHost) {
            this._HostPersonalizado = pHost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "PortaPersonalizada", {
        set: function (pPorta) {
            this._PortaPersonalizada = pPorta;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "LastPong", {
        get: function () {
            return this._FLastPong;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "ServidorAlternativo1Endereco", {
        set: function (url) {
            this.FServidorAlternativo1Endereco = url;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "ServidorAlternativo1Porta", {
        set: function (port) {
            this.FServidorAlternativo1Porta = port;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "ServidorAlternativo2Endereco", {
        set: function (url) {
            this.FServidorAlternativo2Endereco = url;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "ServidorAlternativo2Porta", {
        set: function (port) {
            this.FServidorAlternativo2Porta = port;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "ServidorPrincipalEndereco", {
        set: function (url) {
            this.FServidorPrincipalEndereco = url;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WsClient.prototype, "ServidorPrincipalPorta", {
        set: function (port) {
            this.FServidorPrincipalPorta = port;
        },
        enumerable: false,
        configurable: true
    });
    return WsClient;
}());
exports.WsClient = WsClient;
